import { useState } from 'react';
import { PubSub, EVENTS } from '@rc-d2c/context-manager';
import { RcCheckbox } from '@rc-d2c/shared-ui/RcCheckbox';
import { RcSelect } from '@rc-d2c/shared-ui/RcSelect';
import { RcButton } from '@rc-d2c/shared-ui/RcButton';

import styles from '../../styles/common.module.scss';

const AddToCartCard = ({ title, payload }: { title: string; payload: any }) => {
  const [mainItem, setMainItem] = useState(payload.mainItem);
  const [skuId, setSkuId] = useState(payload.skuId);
  const [ean, setEan] = useState(payload.ean);
  const [quantity, setQuantity] = useState(payload.quantity);
  const [isSubscriptionChecked, setIsSubscriptionChecked] = useState(payload.isSubscriptionChecked);
  const [selectedOption, setSelectedOption] = useState(payload.periodTypeId);

  return (
    <div className={styles.card}>
      <p className={styles.title}>{title}</p>
      <form className={styles.form}>
        <div>
          <label className={styles.cardLabel} htmlFor="mainItem">
            Product ID
          </label>
          <input type="text" name="mainItem" id="mainItem" value={mainItem} onChange={(e) => setMainItem(e.target.value)} />
        </div>
        <div>
          <label className={styles.cardLabel} htmlFor="skuId">
            Sku ID
          </label>
          <input type="text" name="skuId" id="skuId" value={skuId} onChange={(e) => setSkuId(e.target.value)} />
        </div>
        <div>
          <label className={styles.cardLabel} htmlFor="ean">
            Ean
          </label>
          <input type="text" name="ean" id="ean" value={ean} onChange={(e) => setEan(e.target.value)} />
        </div>
        <div>
          <label className={styles.cardLabel} htmlFor="quantity">
            Quantity
          </label>
          <input type="text" name="quantity" id="quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
        </div>
      </form>
      <div>
        <RcCheckbox
          isChecked={isSubscriptionChecked}
          onChange={() => setIsSubscriptionChecked(!isSubscriptionChecked)}
          label="Subscritpion"
        />
      </div>

      {isSubscriptionChecked && (
        <RcSelect
          name="frequency"
          label="Frequency"
          options={[
            {
              value: '74090',
              content: '3 weeks',
            },
            {
              value: '74089',
              content: '2 weeks',
            },
            {
              value: '74088',
              content: '1 week',
            },
            {
              value: '71869',
              content: '11 weeks (club)',
            },
            {
              value: '71868',
              content: '10 weeks (club)',
            },
            {
              value: '71867',
              content: '9 weeks (club)',
            },
            {
              value: '61708',
              content: '1 week (club)',
            },
            {
              value: '61709',
              content: '2 weeks (club)',
            },
            {
              value: '61710',
              content: '3 weeks (club)',
            },
            {
              value: '61711',
              content: '4 weeks (club)',
            },
            {
              value: '61712',
              content: '5 weeks (club)',
            },
            {
              value: '61713',
              content: '6 weeks (club)',
            },
            {
              value: '61714',
              content: '7 weeks (club)',
            },
            {
              value: '61715',
              content: '8 weeks (club)',
            },
          ]}
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
          translations={{
            scrollDownAria: 'Scroll to down',
            scrollUpAria: 'Scroll to up',
          }}
        />
      )}
      <RcButton
        onClick={() => {
          PubSub.publish(EVENTS.ADD_TO_CART, {
            ...payload,
            mainItem,
            skuId,
            ean,
            quantity,
            isSubscriptionChecked,
            periodTypeId: selectedOption,
          });
        }}
        label="Add to cart"
      />
    </div>
  );
};

export default AddToCartCard;
